import { FC } from 'react';
import Head from 'next/head';
import { Button, Container, Grid, Result, Text } from '@/core';
import * as S from '@/lib/styles/pages';

const SubTitle = () => {
  return (
    <Text>
      Página não encontrada.
      <br />
      Por favor, clique em voltar.
    </Text>
  );
};

const Custom404: FC = () => (
  <>
    <Head>
      <title>Página não encontrada</title>
    </Head>
    <Container>
      <S.Wrapper>
        <Grid type="row">
          <Grid type="col" xs={24}>
            <Result
              status="404"
              title="404"
              subTitle={<SubTitle />}
              extra={
                <Button type="link" href="/">
                  Voltar
                </Button>
              }
            />
          </Grid>
        </Grid>
      </S.Wrapper>
    </Container>
  </>
);

export default Custom404;
